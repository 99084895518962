<template>
  <v-dialog v-model="active" width="60%" :fullscreen="$vuetify.breakpoint.mobile">
    <v-card v-if="event">
      <v-card-title :class="('d-flex flex-row text-h5 ' + (event.kariyotei ? 'yellow red--text' : 'purple white--text'))">
        <span class="d-inline-block flex-grow-1">来店予約の編集</span>
        <span v-if="event.kariyotei" class="d-inline-block text-caption font-weight-bold">仮予定</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="overflow-y-auto" style="height: 70vh">
        <v-form>
          <v-row dense>
            <v-col>
              <date-picker v-model="event.start" label="日付" hide-details="auto"></date-picker>
            </v-col>
            <v-col>
              <time-picker v-model="event.start" label="開始時刻" font hide-details="auto"></time-picker>
            </v-col>
            <v-col>
              <time-picker v-model="event.end" label="終了時刻" hide-details="auto"></time-picker>
            </v-col>
            <v-col>
              <v-select :items="places" label="場所" item-text="abbr" v-model="event.category" return-object
                prepend-icon="mdi-form-select" hide-details="auto"></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex flex-row align-end">
              <v-select v-model="event.celemony.year" :items="years" label="年度" prepend-icon="mdi-form-select"
                hide-details="auto" class="flex-grow-1"></v-select>
              <v-chip v-if="wareki" color="primary" style="width:7rem;text-align:center">
                {{ wareki }}</v-chip>
            </v-col>
            <v-col>
              <v-select :items="[
                { value: 1, text: '振袖' },
                { value: 2, text: '女袴' },
                { value: 3, text: '男袴' },
                { value: 4, text: '手入納品' },
                { value: 5, text: '仕上納品' },
                { value: 6, text: '手入持込' },
                { value: 7, text: '入金' },
                { value: 8, text: '振袖見直し' },
                { value: 10, text: '販売' },
                { value: 9, text: 'その他' },
              ]" label="区分" item-text="text" v-model="event.kubun" return-object prepend-icon="mdi-form-select"
                hide-details="auto"></v-select>
            </v-col>
            <v-col>
              <v-text-field v-model="event.customer.name" label="お名前" prepend-icon="mdi-syllabary-hiragana"
                hide-details="auto"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="event.city" label="市町村" prepend-icon="mdi-syllabary-hiragana"
                hide-details="auto"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field v-model="event.tel" label="電話" prepend-icon="mdi-syllabary-hiragana"
                hide-details="auto"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="event.mobileTel" label="携帯" prepend-icon="mdi-syllabary-hiragana"
                hide-details="auto"></v-text-field>
            </v-col>
            <v-col>
              <v-select :items="[
                { value: 1, text: 'ホームページ' },
                { value: 2, text: 'LINE' },
                { value: 3, text: '電話' },
                { value: 4, text: 'ルーツ' },
              ]" label="来店経緯" item-text="text" v-model="event.raiten" return-object prepend-icon="mdi-form-select"
                hide-details="auto"></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select :items="users" label="受付担当" item-text="name" v-model="event.salesStaff" return-object
                prepend-icon="mdi-form-select" hide-details="auto"></v-select>
            </v-col>
            <v-col>
              <date-picker v-model="event.receipt" label="受付日" hide-details="auto"></date-picker>
            </v-col>
            <v-col>
              <v-combobox v-model="event.visitors" return-object :items="['本人', '母', '父', '祖母', '祖父', 'その他']" label="来店者"
                multiple></v-combobox>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="event.visitorCount" label="来店人数" hide-details="auto"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select v-model="event.hairMake" :items="hairMakes" label="ヘアーメイク体験" prepend-icon="mdi-form-select"
                hide-details="auto"></v-select>
            </v-col>
            <v-col>
              <v-text-field v-model="event.hairMakeSonota" label="その他" prepend-icon="mdi-syllabary-hiragana"
                hide-details="auto" v-if="event.hairMake == 9"></v-text-field>
            </v-col>
            <v-col>
              <v-select v-model="event.zenjitsuRenraku" :items="zenjitsuRenrakus" label="前日ご連絡"
                prepend-icon="mdi-form-select" hide-details="auto"></v-select>
            </v-col>
          </v-row>
          <v-row v-if="false">
            <v-col>
              <v-expansion-panels accordion focusable>
                <v-expansion-panel>
                  <v-expansion-panel-header>連絡先</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col md="3">
                        <v-text-field v-model="event.customer.postal" label="郵便番号" prepend-icon="mdi-numeric"
                          hide-details="auto" @input="getYubinBango"></v-text-field>
                      </v-col>
                      <v-col md="9">
                        <v-col cols="12">
                          <v-text-field v-model="event.customer.address1" label="住所1"
                            prepend-icon="mdi-syllabary-hiragana" hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="event.customer.address2" label="住所2"
                            prepend-icon="mdi-syllabary-hiragana" hide-details="auto"></v-text-field>
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-row>
                          <v-col>
                            <v-text-field v-model="event.customer.telHome" label="自宅電話番号" prepend-icon="mdi-numeric"
                              hide-details="auto"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field v-model="event.customer.telDaughter" label="娘電話番号" prepend-icon="mdi-numeric"
                              hide-details="auto"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col>
                            <v-text-field v-model="event.customer.telMother" label="代表電話番号(母)" prepend-icon="mdi-numeric"
                              hide-details="auto"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field v-model="event.customer.telFather" label="代表電話番号(父)" prepend-icon="mdi-numeric"
                              hide-details="auto"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>成人式</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col md="5" class="pa-4">
                        <v-chip v-if="wareki" color="primary">
                          {{ wareki }}</v-chip>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col md="3">
                        <v-select v-model="event.celemony.onTheDay" :items="onTheDays" label="当日"
                          prepend-icon="mdi-form-select" hide-details="auto"></v-select>
                      </v-col>
                      <v-col md="9">
                        <v-text-field v-model="event.celemony.ontheDayDetail" label="当日詳細"
                          prepend-icon="mdi-syllabary-hiragana" hide-details="auto"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select v-model="event.celemony.todoke" :items="todoke" label="届け"
                          prepend-icon="mdi-form-select" hide-details="auto"></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field v-model="event.celemony.hagaki" label="ハガキ・LINE" prepend-icon="mdi-calendar"
                          hide-details="auto" hint="送付済の場合日付を記入"></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field v-model="event.celemony.hagakiSender" label="誰が送った？"
                          prepend-icon="mdi-syllabary-hiragana" hide-details="auto" hint="送付担当者の氏名を記入"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select v-model="event.celemony.line" :items="line" label="LINE登録"
                          prepend-icon="mdi-form-select" hide-details="auto"></v-select>
                      </v-col>
                      <v-col>
                        <v-select v-model="event.celemony.familyPhoto" :items="familyPhotos" label="家族写真"
                          prepend-icon="mdi-form-select"></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field v-model="event.celemony.familyPhotoMemo" label="家族写真備考"
                          prepend-icon="mdi-syllabary-hiragana"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea label="備考" v-model="event.remarks" prepend-icon="mdi-syllabary-hiragana"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>結果</h3>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col md="3" class="pt-0">
              <v-select :items="users" label="接客者①" item-text="name" v-model="event.serviceStaff1" return-object
                prepend-icon="mdi-form-select" hide-details="auto" class="pt-0"></v-select>
            </v-col>
            <v-col md="3" class="pt-0">
              <v-select :items="users" label="接客者②" item-text="name" v-model="event.serviceStaff2" return-object
                prepend-icon="mdi-form-select" hide-details="auto" class="pt-0"></v-select>
            </v-col>
            <v-col md="3" class="pt-0">
              <v-select :items="add_kbn" label="区分" v-model="event.add_kbn" prepend-icon="mdi-form-select"
                hide-details="auto" class="pt-0"></v-select>
            </v-col>
            <v-col md="3" class="pt-0">
              <v-text-field v-model="event.kingaku" label="金額" class="pt-0" prepend-icon="mdi-numeric"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" rounded @click="endEdit"> 更新 </v-btn>
        <v-btn outlined rounded text @click="cancelEdit"> キャンセル </v-btn>
        <v-btn outlined rounded text @click="moveEvent" color="secondary" v-if="!movingMode">
          移動
        </v-btn>
        <v-btn v-if="currentUser.role == 'admin'" color="error" rounded text @click="removeConfirmationDialog = true">
          削除
        </v-btn>
        <v-spacer></v-spacer>
        <v-switch v-model="event.kariyotei" label="仮予定にする">仮予定にする</v-switch>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="removeConfirmationDialog" max-width="400">
      <v-card>
        <v-card-title>
          <div>削除確認</div>
        </v-card-title>
        <v-card-text>
          <p>削除すると元に戻せません。本当に削除しますか？</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="removeConfirmationDialog = false">キャンセル</v-btn>
          <v-btn @click="remove()" color="error">削除する</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import TimePicker from "../forms/TimePicker.vue";
import DatePicker from "../forms/DatePicker.vue";
import { mapActions, mapState } from "vuex";
import { Core as YubinBangoCore } from "yubinbango-core2";

export default {
  props: {
    value: {},
    event: null,
  },
  components: {
    TimePicker,
    DatePicker,
  },
  data: () => ({
    flg: true,
    selectedCustomer: null,
    hairMakes: [
      { text: "該当なし", value: 0 },
      { text: "有", value: 1 },
      { text: "無", value: 2 },
      { text: "直接スタジオへ", value: 3 },
      { text: "本店へ寄る", value: 4 },
      { text: "その他", value: 9 },
    ],
    zenjitsuRenrakus: [
      { text: "なし", value: 0 },
      { text: "未", value: 1 },
      { text: "済", value: 2 },
    ],
    onTheDays: [
      { text: "該当なし", value: 0 },
      { text: "有", value: 1 },
      { text: "美容", value: 2 },
      { text: "先方", value: 3 },
    ],
    familyPhotos: [
      { text: "あり", value: 0 },
      { text: "なし", value: 1 },
      { text: "要確認", value: 2 },
    ],
    todoke: [
      { text: "―", value: 2 },
      { text: "未", value: 0 },
      { text: "済", value: 1 },
      { text: "小物のみ済", value: 3 },
    ],
    line: [
      { text: "なし", value: 0 },
      { text: "本人のみ", value: 1 },
      { text: "保護者のみ", value: 2 },
      { text: "本人・保護者", value: 3 },
    ],
    add_kbn: [
      { text: "", value: 0 },
      { text: "購入", value: 1 },
      { text: "レンタル", value: 2 },
      { text: "オーダー", value: 3 },
      { text: "小物", value: 4 },
      { text: "スタジオ成人式", value: 5 },
      { text: "袴", value: 6 },
      { text: "一般", value: 7 },
      { text: "未決", value: 8 },
      { text: "その他", value: 99 },
    ],
    years: [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032],
    customers: [],
    customerSearch: "",
    customerLoading: false,
    originalEvent: null,
    passwordDialog: false,
    historyDialog: false,
    nameHistory: [],
    removeConfirmationDialog: false,
  }),
  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapState(["movingMode"]),
    ...mapState("users", {
      users: (state) => state.data.slice().sort((a, b) => a.code - b.code),
    }),
    ...mapState("place", { places: "data" }),
    ...mapState("eventTypes", {
      eventTypes: (state) =>
        state.data.slice().sort((a, b) => a.value - b.value),
    }),
    active: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    wareki: function () {
      if (this.event.celemony.year) {
        const dateText = String(this.event.celemony.year) + "-01-01";
        const dateValue = new Date(dateText);
        const result = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
          era: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(dateValue);
        return result.substring(0, result.indexOf("年") + 1);
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions(["startMove"]),
    ...mapActions("visits", ["reserveAsync", "removeAsync"]),
    getYubinBango(input) {
      if (input.length == 7) {
        console.log(input);
        new YubinBangoCore(input, (addr) => {
          this.event.customer.address1 = addr.region; // 都道府県
          this.event.customer.address1 += addr.locality; // 市区町村
          this.event.customer.address1 += addr.street; // 町域
          this.event = Object.assign({}, this.event);
        });
      }
    },
    moveEvent() {
      this.startMove({ move: true, event: this.event });
      this.$emit("move", this.event);
      this.active = false;
    },
    dialogResult(result) {
      if (result.result == "ok") {
        this.event.updatedBy = result.user;
        this.endEdit();
      }
    },
    endEdit() {
      if (this.event.kubun && this.event.kubun.value == 1) {
        this.event.color = "red";
      } else if (this.event.kubun && this.event.kubun.value == 10) {
        this.event.color = "blue";
      } else {
        this.event.color = "#666666";
      }
      if (this.event.kariyotei) {
        this.event.color = 'yellow'
      }
      this.event.place = this.event.category.id;
      //console.log({saved:this.event})
      this.$emit("ok", this.event);
      this.active = false;
    },
    cancelEdit() {
      this.$emit("cancel", this.event);
      this.active = false;
    },
    showHistory() {
      this.historyDialog = true;
    },
    async reserve() {
      await this.reserveAsync(this.event);
      this.$emit("reserve", this.event);
      this.active = false;
    },
    async remove() {
      this.removeAsync(this.event);
      this.active = false;
    },
  },
};
</script>
